<template>
  <div class="examinerManagement">
    <div class="flex-box">
      <div class="left">
        <el-button class="marginRight"
                   type="success"
                   @click="addCourseTime">添加数据</el-button>
        <el-button @click="selectDownLoadTemplateType(2)"
                   type="primary"
                   icon="el-icon-upload2">选择导入模板类型
        </el-button>
        <el-button class="marginRight"
                   type="primary"
                   icon="el-icon-document"
                   @click="selectDownLoadTemplateType(1)">选择下载模板类型</el-button>
        <el-button class="marginRight"
                   type="danger"
                   icon="el-icon-document"
                   @click="batchDelete">批量删除</el-button>
      </div>
    </div>

    <div style=""
         class="right">
      <el-select filterable
                 @clear="queryCourseHourPageList(1)"
                 v-model="query.courseId"
                 clearable
                 placeholder="请选择课程">
        <el-option v-for="item in courseTimeSelectList"
                   :key="item.id"
                   :label="item.course"
                   :value="item.id">
        </el-option>
      </el-select>
      <el-select @clear="queryCourseHourPageList(1)"
                 class="picker"
                 v-model="query.type"
                 clearable
                 placeholder="请选择类型">
        <el-option v-for="item in subjectTypeList"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id">
        </el-option>
      </el-select>
      <el-select filterable
                 @clear="queryCourseHourPageList(1)"
                 class="picker"
                 v-model="query.mainTeachId"
                 clearable
                 placeholder="请选择主教">
        <el-option v-for="item in teacherList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
        </el-option>
      </el-select>
      <el-input v-model="query.name"
                class="picker"
                placeholder="请输入名称"></el-input>
      <el-button @click="queryCourseHourPageList(1)"
                 class="picker"
                 type="primary">查 询</el-button>
    </div>

    <el-table :data="courseTimeList"
              @selection-change="batchSelectChange">
      <el-table-column type="selection"
                       key="index2"
                       align="center"></el-table-column>
      <el-table-column label="序号"
                       width="60"
                       type="index"
                       align="center"></el-table-column>
      <el-table-column label="年级"
                       prop="gradeName"
                       align="center">
      </el-table-column>
      <el-table-column label="班级"
                       prop="classGroup"
                       align="center"></el-table-column>
      <el-table-column label="学科"
                       prop="courseName"
                       align="center"></el-table-column>
      <el-table-column label="名称"
                       prop="name"
                       align="center"></el-table-column>
      <el-table-column label="周课时（小时）"
                       prop="hours"
                       align="center"></el-table-column>
      <el-table-column label="学科类型"
                       align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 0">基础课</div>
          <div v-if="scope.row.type === 1">小组课</div>
          <div v-if="scope.row.type === 2">个训课</div>
        </template>
      </el-table-column>

      <el-table-column label="主教"
                       prop="main_teacher"
                       align="center"></el-table-column>

      <el-table-column label="操作"
                       width="300px"
                       prop="course"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="editCourseTime(scope.row)">修改
          </el-button>
          <el-button type="danger"
                     @click="deleteCourseTime(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="queryCourseHourPageList"></el-pagination>

    <el-dialog :title="addCourseTimeForm.id !== 0 ? '编辑学科课时' : '添加学科课时'"
               :visible.sync="addCourseTimeDialogVisible"
               width="600px">
      <div class="center">
        <el-form :model="courseTimeForm"
                 ref="courseTimeFormRef"
                 label-width="120px"
                 :rules="courseTimeFormRules">
          <el-form-item label="学科类型："
                        prop="subjectType">
            <el-select @change="subjectTypeChange"
                       v-model="courseTimeForm.subjectType"
                       clearable
                       placeholder="请选择学科类型">
              <el-option v-for="obj in subjectTypeList"
                         :key="obj.id"
                         :label="obj.value"
                         :value="obj.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="学科："
                        prop="subjectId">
            <el-select v-model="courseTimeForm.subjectId"
                       clearable
                       placeholder="请选择学科">
              <el-option v-for="obj in courseTimeSelectList"
                         :key="obj.id"
                         :label="obj.course"
                         :value="obj.id" />
            </el-select>
          </el-form-item>

          <el-form-item label="名称："
                        prop="name">
            <el-input v-model="courseTimeForm.name"
                      placeholder="请输入名称"></el-input>
          </el-form-item>

          <el-form-item label="周课时："
                        prop="hour">
            <el-input v-model="courseTimeForm.hour"
                      placeholder="请输入周课时（小时）"></el-input>
          </el-form-item>
          <el-form-item label="教室："
                        prop="classRoom">
            <el-select v-model="courseTimeForm.classRoom"
                       clearable
                       placeholder="请选择教室">
              <el-option v-for="obj in classroomList"
                         :key="obj.id"
                         :label="obj.room_name"
                         :value="obj.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="主教："
                        prop="mainRTeacher">
            <el-select filterable
                       v-model="courseTimeForm.mainRTeacher"
                       clearable
                       placeholder="请选择主教">
              <el-option v-for="obj in teacherList"
                         :key="obj.id"
                         :label="obj.name"
                         :value="obj.id" />
            </el-select>
          </el-form-item>

          <template v-if="visible">
            <el-form-item v-if="basicShow"
                          label="年级："
                          prop="gradeId">
              <el-select @change="gradeSelectChange"
                         v-model="courseTimeForm.gradeId"
                         clearable
                         placeholder="请选择年级">
                <el-option v-for="obj in gradeList"
                           :key="obj.id"
                           :label="obj.gname"
                           :value="obj.id" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="basicShow"
                          label="班级："
                          prop="classGroup">
              <el-select multiple
                         v-model="courseTimeForm.classGroup"
                         clearable
                         placeholder="请根据年级选择班级">
                <el-option v-for="obj in allGradeClassList"
                           :key="obj.id"
                           :label="obj.cname"
                           :value="obj.id" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="!groupShow"
                          label="连堂课时："
                          prop="followNum">
              <el-input v-model="courseTimeForm.followNum"
                        placeholder="至少连堂一节"></el-input>
            </el-form-item>

            <!-- <el-form-item v-if="!personalShow" label="是否多类型：">
              <el-radio v-model="isMultiType" :label="1">是</el-radio>
              <el-radio v-model="isMultiType" :label="0">否</el-radio>
            </el-form-item> -->

            <el-form-item v-if="!personalShow"
                          label="辅教人数："
                          prop="assistTeachNum">
              <el-input v-model="courseTimeForm.assistTeachNum"
                        placeholder="非多类型请输入0"></el-input>
            </el-form-item>

            <el-form-item v-if="!basicShow"
                          label="学生："
                          prop="studentGroup">
              <el-select multiple
                         @change="studentChange"
                         v-model="courseTimeForm.studentGroup"
                         clearable
                         placeholder="请选择学生">
                <el-option v-for="obj in studentList"
                           :key="obj.id"
                           :label="obj.nickname"
                           :value="obj.id" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="!basicShow"
                          label="学生人数："
                          prop="studentNum">
              <el-input v-model="courseTimeForm.studentNum"
                        placeholder="请输入学生人数"></el-input>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addCourseTimeDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="courseTimeConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="templateType"
               :visible.sync="templateDialogVisible"
               width="600px">
      <div class="">
        <div>
          <el-radio v-model="type"
                    :label="0">基础课</el-radio>
          <el-radio v-model="type"
                    :label="1">小组课</el-radio>
          <el-radio v-model="type"
                    :label="2">个训课</el-radio>
        </div>
        <div style="margin-top: 20px">
          <el-upload v-if="templateType == '选择导入模板类型'"
                     action="/api/course/hour/importCourseHour"
                     :data="{ type: type }"
                     class="display marginRight"
                     :headers="uploadHeader"
                     name="uploadFile"
                     :on-success="uploadSuccess"
                     :show-file-list="false">
            <el-button type="primary"
                       icon="el-icon-upload2">导 入 </el-button>
          </el-upload>
        </div>
      </div>
      <span v-if="templateType == '选择下载模板类型'"
            slot="footer"
            class="dialog-footer">
        <el-button @click="templateDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="DownloadTemplate">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "templateCourseTime",
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      teachersList: [],
      // 表单
      addCourseTimeForm: {
        id: 0,
        gradeId: "",
        // gradeName:'',
        subjectId: "",
        subjectName: "",
        hour: "",
        schoolYearId: "",
        subjectType: "",
        // 是否多类型
        isMultiType: 1,
      },
      // 学科类型列表
      subjectTypeList: [
        { id: 0, value: "基础课" },
        { id: 1, value: "小组课" },
        { id: 2, value: "个训课" },
      ],
      basicShow: false,
      groupShow: false,
      personalShow: false,
      visible: false,
      // 验证规则
      addCourseTimeFormRules: {
        id: [{ required: true }],
        gradeId: [{ required: true }],
        subjectId: [{ required: true }],
        subjectName: [{ required: true }],
        hour: [{ required: true }],
        schoolYearId: [{ required: true }],
        subjectType: [{ required: true }],
        isMultiType: [{ required: true }],
      },
      // 添加学科课时对话框显示与隐藏
      addCourseTimeDialogVisible: false,
      // 学科课时选择列表
      courseTimeSelectList: [],
      // 学期id
      schoolYearId: Number(this.$route.query.termid),
      // 学科课时列表
      courseTimeList: [],
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      // 批量删除列表
      batchDeleteList: [],
      // 年级
      gradeList: [],
      teacherList: [],
      allGradeClassList: [],
      classroomList: [],
      studentList: [],
      value: [],
      courseTimeForm: {
        id: "",
        subjectType: "",
        subjectId: "",
        hour: "",
        classRoom: "",
        mainRTeacher: "",
        gradeId: "",
        classGroup: "",
        followNum: "",
        studentGroup: "",
        studentNum: "",
        assistTeachNum: "",
        name: "",
      },
      courseTimeFormRules: {
        subjectType: [{ required: true }],
        subjectId: [{ required: true }],
        hour: [{ required: true }],
        classRoom: [{ required: true }],
        mainRTeacher: [{ required: true }],
        gradeId: [{ required: true }],
        classGroup: [{ required: true }],
        followNum: [{ required: true }],
        assistTeachNum: [{ required: true }],
        studentGroup: [{ required: true }],
        studentNum: [{ required: true }],
        name: [{ required: true }],
      },
      isFollowCourse: "",
      isMultiType: "",
      gradeClasslist: [],
      type: "",
      templateDialogVisible: false,
      templateType: "选择下载模板类型",
      query: {
        courseId: "",
        type: "",
        name: "",
        mainTeachId: "",
      },
    };
  },
  created() {
    this.loadAllTeacher();
    this.loadClassroom();
    this.loadStudent();
    this.queryAllGradeList();
    this.queryCourse();

    this.queryCourseHourPageList(1);
  },
  methods: {
    queryCourseHourPageList(page) {
      let data = {
        indexNo: page,
        rows: 10,
        yearId: this.schoolYearId,
        courseId: this.query.courseId,
        type: this.query.type,
        name: this.query.name,
        mainTeachId: this.query.mainTeachId,
      };
      this.$postJson(`/course/hour/queryCourseHourPageList`, data).then(
        (res) => {
          this.courseTimeList = res.data.data;
          let page1 = res.data.totalCount / 10;
          this.page.currentPage = page;
          this.page.pageCount = page1 < 1 ? 1 : Math.ceil(page1);
        }
      );
    },
    courseTimeConfirm() {
      let classGroup;
      let studentGroup;
      if (this.courseTimeForm.classGroup) {
        classGroup = this.courseTimeForm.classGroup.join();
      }

      if (this.courseTimeForm.studentGroup) {
        studentGroup = this.courseTimeForm.studentGroup.join();
      }

      let data = {
        id: this.courseTimeForm.id,
        type: this.courseTimeForm.subjectType,
        gradeId: this.courseTimeForm.gradeId,
        classGroup: classGroup,
        courseId: this.courseTimeForm.subjectId,
        hours: this.courseTimeForm.hour,
        followNum: this.courseTimeForm.followNum,
        classroomId: this.courseTimeForm.classRoom,
        mainTeachId: this.courseTimeForm.mainRTeacher,
        assistTeachNum: this.courseTimeForm.assistTeachNum,
        studentGroup: studentGroup,
        name: this.courseTimeForm.name,
      };
      this.$refs.courseTimeFormRef.validate((validate) => {
        if (validate) {
          this.$postJson(`/course/hour/submitCourseHour`, data)
            .then((res) => {
              if (this.courseTimeForm.id) {
                this.$message({
                  type: "success",
                  message: "修改成功！",
                });
                this.queryCourseHourPageList(this.page.currentPage);
              } else {
                this.$message({
                  type: "success",
                  message: "添加成功！",
                });
                this.queryCourseHourPageList(1);
              }

              this.addCourseTimeDialogVisible = false;
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err.message,
              });
            });
        }
      });
    },
    gradeSelectChange(value) {
      // this.courseTimeForm[name] = value;
      const data = { gradeId: value };
      this.$post(`/media/queryClassesByYearAndGrade.do`, data).then((res) => {
        this.allGradeClassList = res.data;
      });
    },
    batchSelectChange(list) {
      console.log(list);
      this.batchDeleteList = list.map((item) => item.id).join(",");
      console.log(this.batchDeleteList);
    },
    studentChange(value) {
      this.courseTimeForm.studentGroup = value;
      this.courseTimeForm.studentNum = value.length;
    },
    // 查询学生
    loadStudent() {
      let data = { indexNo: -1 };
      this.$post(`/media/queryStudent.do`, data).then((res) => {
        this.studentList = res.data.rows;
      });
    },
    // 查询教室
    loadClassroom() {
      this.$post("/base/queryClassroom.do")
        .then((res) => {
          this.classroomList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 查询教师
    loadAllTeacher() {
      const that = this;
      // let data = { is_no: 1 }; //查询身份是否校内，1是校内
      this.$post("/media/queryAllManager.do")
        .then((res) => {
          that.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 查询所有年级列表
    queryAllGradeList() {
      this.$post(`/media/queryAllclasses.do`).then((res) => {
        this.gradeList = res.data;
      });
    },
    // 学科批量删除
    batchDelete() {
      if (!this.batchDeleteList.length) {
        this.$message({
          type: "info",
          message: "请先选择要删除的数据！",
        });
        return;
      }

      this.$confirm("是否批量删除这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(`/course/hour/deleteCourseHour/${this.batchDeleteList}`)
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.queryCourseHourPageList(this.page.currentPage);
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 学科查询
    queryCourse() {
      this.$postJson("/media/queryCourse.do").then((res) => {
        console.log(res);
        this.courseTimeSelectList = res.data;
      });
    },
    // 选择学科类型时
    subjectTypeChange(value) {
      this.visible = true;
      // this.addCourseTimeForm.subjectType = value;
      this.courseTimeForm.subjectType = value;
      if (value === 0) {
        this.basicShow = true;
        this.groupShow = false;
        this.personalShow = false;
      } else if (value === 1) {
        this.basicShow = false;
        this.groupShow = true;
        this.personalShow = false;
      } else if (value === 2) {
        this.basicShow = false;
        this.groupShow = false;
        this.personalShow = true;
      }
    },
    // 添加学科课时
    addCourseTime() {
      this.visible = false;

      this.addCourseTimeDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.courseTimeFormRef.resetFields();
        this.courseTimeForm.id = "";
        this.courseTimeForm.subjectType = "";
        this.isMultiType = "";
        this.isFollowCourse = "";
        this.courseTimeForm.subjectId = "";
        this.courseTimeForm.hour = "";
        this.courseTimeForm.classRoom = "";
        this.courseTimeForm.mainRTeacher = "";
        this.courseTimeForm.gradeId = "";
        this.courseTimeForm.classGroup = "";
        this.courseTimeForm.followNum = "";
        this.courseTimeForm.studentGroup = "";
        this.courseTimeForm.studentNum = "";
        this.courseTimeForm.assistTeachNum = "";
      });
      console.log(this.courseTimeForm);
    },
    editCourseTime(row) {
      this.$get(`/course/hour/queryCourseHourDetail/${row.id}`).then((res) => {
        this.addCourseTimeDialogVisible = true;
        this.subjectTypeChange(res.data.type);
        this.gradeSelectChange(res.data.gradeId);
        let classGroup;
        let studentGroup;
        if (res.data.classGroup != null) {
          classGroup = res.data.classGroup.split(",").map((item) => +item);
        }
        if (res.data.studentGroup != null) {
          studentGroup = res.data.studentGroup.split(",").map((item) => +item);
        }
        this.$nextTick(() => {
          this.courseTimeForm.id = row.id;
          this.courseTimeForm.subjectId = res.data.courseId;
          this.courseTimeForm.name = row.name;
          this.courseTimeForm.hour = row.hours;
          this.courseTimeForm.classRoom = res.data.classroomId;
          this.courseTimeForm.mainRTeacher = res.data.mainTeachId;
          this.courseTimeForm.gradeId = res.data.gradeId;
          this.courseTimeForm.classGroup = classGroup;
          this.courseTimeForm.followNum = res.data.followNum;
          this.courseTimeForm.studentGroup = studentGroup;
          this.courseTimeForm.studentNum = studentGroup
            ? studentGroup.length
            : "";
          this.courseTimeForm.assistTeachNum = res.data.assistTeachNum;

          this.isFollowCourse = this.courseTimeForm.followNum ? 1 : 0;
          this.isMultiType = this.courseTimeForm.assistTeachNum ? 1 : 0;
        });
      });
    },
    // 删除
    deleteCourseTime(id) {
      const that = this;
      this.$confirm("是否移除此条记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(`/course/hour/deleteCourseHour/${id}`)
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.queryCourseHourPageList(this.page.currentPage);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 导入
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.queryCourseHourPageList(1);
        this.templateDialogVisible = false;
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    importTemplate() {
      this.templateDialogVisible = true;
    },
    selectDownLoadTemplateType(type) {
      if (type === 1) {
        this.templateType = "选择下载模板类型";
      } else {
        this.templateType = "选择导入模板类型";
      }
      this.templateDialogVisible = true;
    },
    // 下载模板
    DownloadTemplate() {
      const that = this;
      this.$postFile(`/course/hour/downloadCourseHour?type=${this.type}`)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "学科学时导入模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);

          this.templateDialogVisible = false;
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.display {
  display: inline-block;
}

.marginRight {
  margin-right: 10px;
}

.right {
  margin-top: 20px;
  /* display: flex;
  justify-content: flex-end; */
}
</style>
